<template>
  <footer class="app-footer">
    <help />

    <div class="card text-center">
      <p>{{ $t("Une solution de télé-expertise labellisée proposée par") }} <strong>CrossDoc</strong>.</p>
      <p>
        <a href="https://crossdoc.fr?utm_source=infovac.ch" target="_blank">
          <img src="/images/CrossDoc-logo.svg" :alt="`CrossDoc, ${$t('télé-expertise labellisée')}`" height="56" width="156" />
        </a>
      </p>
    </div>
  </footer>
</template>

<script>
import Help from "@/components/Help.vue";

export default {
  name: "AppFooter",
  components: {
    Help,
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/99-utilities/utilities.align";
@import "src/scss/99-utilities/utilities.text";

.app-footer {
  padding: 1rem;

  img {
    display: inline;
  }
}
.card {
  padding: 1rem;
}
.flex-item {
  padding: 1rem;
  margin: 0 !important;
}
</style>
