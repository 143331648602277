// Vue
import Vue from "vue";
Vue.config.productionTip = false;

// Router (VueRouter)
import router from "./router.js";

// Vue head
import VueHead from "vue-head";
Vue.use(VueHead);

// Store (vuex)
import store from "./store.js";

// NProgress
import { NProgress } from "./nprogress.js";

// Axios
import { appConfig } from "./config.js";
import { axios, HTTP } from "./http-common.js";

// PWA
// import "./registerServiceWorker.js";

// Font Loader (FontFaceObserver)
import themeFontLoader from "./libs/font-loader.js";
themeFontLoader(["Roboto", "Roboto Mono", "Montserrat"]);

// vue-i18n
// import VueI18n from "vue-i18n";
// Vue.use(VueI18n);
import Lang, { i18n } from "@/plugins/lang.js";
Vue.use(Lang);

// Notifications
import Toasted from "vue-toasted";
import { toastedDefaults, toastedSuccess, toastedError, toastedDefaultConfirm } from "./libs/toasted-options.js";

Vue.use(Toasted);

Vue.toasted.register(
  "appError",
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return i18n.t("Une erreur inconnue est survenue !");
    }
    // if there is a message show it with the message
    // ❗⚠ ⚠️
    return `<div class="message-wrapper">${payload.message}</div>`;
  },
  toastedError
);

Vue.toasted.register(
  "appSuccess",
  (payload) => {
    // ✓
    return `<div class="message-wrapper">${payload.message}</div>`;
  },
  toastedSuccess
);

Vue.toasted.register(
  "appHelp",
  (payload) => {
    // ✓
    return `<div class="message-wrapper">${payload.message}</div>`;
  },
  toastedSuccess
);

Vue.toasted.register(
  "appInfo",
  (payload) => {
    return `<div class="message-wrapper">${payload.message}</div>`;
  },
  toastedDefaults
);

// tooltips
import VTooltip from "v-tooltip";
Vue.use(VTooltip);

// dayjs
import Dayjs from "vue-dayjs";
Vue.use(Dayjs);

// Global Route Guards
// Les routes qui nécessitent une authentification ont une meta `authenticated` définie sur `true`
// Ces routes nécessitent que l'utilisateur soit **authentifié** (`store.userData` ne peut pas être indéfini)

router.beforeEach((to, from, next) => {
  // console.log("to.name", to.name);
  // console.log("!!store.state.userData", !!store.state.userData);
  // console.log("parseInt(store.state.userData.registration_complete) === 1", parseInt(store.state.userData.registration_complete) === 1);

  // The user is logged-in, redirect from home to dashboard
  // if (to.name !== "dashboard" && to.name !== "login" && !!store.state.userData && parseInt(store.state.userData.registration_complete) === 1) {
  //   next("/dashboard");
  // }

  // no authentication needed
  if (!to.meta || !to.meta.authenticated) {
    next();
  }

  // route needs an authenticated user
  else {
    // User is not logged-in
    if (!store.state.userData || !store.state.userData.registration_complete) {
      let msg = "";
      const nextRoute = "/login";
      const query = new URLSearchParams();
      const requestedRoute = to.fullPath;

      // L'utilisateur est connecté pour terminer son inscription
      if (!!store.state.userData && !store.state.userData.registration_complete) {
        Vue.toasted.global.appInfo({
          message: i18n.t("Veuillez <strong>compléter votre profil</strong> pour continuer."),
        });
        next("/register");
        return;
      }

      // Redirect to the requested URL after login
      if (to.name !== "root" && to.name !== "login") {
        store.dispatch("SET_REDIRECT_URI", requestedRoute);
        query.append("redirect", requestedRoute);
      }

      // Le token est disponible mais pas les infos utilisateur : session expirée
      if (store.state.userToken) {
        msg = i18n.t("Votre session a expirée, merci de vous connecter.");
        Vue.toasted.global.appError({
          message: msg,
        });
      }

      // Redirect to login
      next(`${nextRoute}?${query.toString()}`);
    }

    // All good
    else {
      next();
    }
  }
});

// Vue filters
import { slugify } from "./libs/helpers";
Vue.filter("slugify", slugify);

// // Frontend Monitoring (LogRocket)
// import LogRocket, { log } from "logrocket";

// if (appConfig.frontendMonitoring) {
// 	// Anamorphik @dev
// 	LogRocket.init("nbv53p/infovac");
// 	console.info("Frontend monitoring ON");
// }

// Main App
import App from "./App.vue";

// auto track routes
Vue.use(router);

// Global event bus
// import { EventBus } from "./event-bus.js";

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n,

  head: {
    title: function() {
      return {
        inner: "Infovac",
        complement: i18n.t("Plateforme de téléexpertise"),
      };
    },

    // Meta tags
    meta: [
      { name: "msapplication-TileColor", content: "#00aba9" },
      { name: "theme-color", content: "#ffffff" },
      {
        name: "description",
        content: "La solution de téléexpertise pour vos questions sur la vaccination.",
      },
    ],
    link: [
      {
        rel: "manifest",
        href: "/manifest.json",
      },
      {
        rel: "apple-touch-icon",
        href: "/apple-touch-icon.png",
        sizes: "32x32",
        type: "image/png",
      },
      {
        rel: "icon",
        href: "/favicon-32x32.png",
        sizes: "32x32",
        type: "image/png",
      },
      {
        rel: "icon",
        href: "/favicon-16x16.png",
        sizes: "16x16",
        type: "image/png",
      },
      {
        rel: "mask-icon",
        href: "/safari-pinned-tab.svg",
        color: "#229ad2",
      },

      // DNS Prefetch
      {
        rel: "prefetch",
        href: "//www.google-analytics.com",
      },

      // Preconnect
      {
        rel: "preconnect",
        href: "//googletagmanager.com",
      },

      // Preload fonts
      {
        rel: "preload",
        href: "/fonts/roboto-mono-v7-latin-regular.0c94e034.woff2",
        as: "font",
      },
      {
        rel: "preload",
        href: "/fonts/roboto-v20-latin_latin-ext-regular.c5bf51b6.woff2",
        as: "font",
      },
      {
        rel: "preload",
        href: "/fonts/montserrat-v14-latin-ext_latin-regular.20f97c00.woff2",
        as: "font",
      },
      {
        rel: "preload",
        href: "/fonts/montserrat-v14-latin-ext_latin-800.2b2c1f44.woff2",
        as: "font",
      },
    ],

    script: [
      {
        type: "text/javascript",
        // src: "https://cdnjs.cloudflare.com/ajax/libs/outdated-browser/1.1.5/outdatedbrowser.min.js",
        src: "/external/outdatedbrowser.min.js",
        async: true,
        body: true,
      },
    ],
  },

  created() {
    // Skip interceptors for the "login" route

    // Network / Authentication error handling
    HTTP.interceptors.response.use(
      (response) => response,
      (error) => {
        NProgress.done();
        console.info("Interceptor error (url,status)", error.config.url, error.response.status);

        // Login - return all errors to original promise (store/login.vue)
        // if (error.config.url === "login" && error.response.status === 403) {
        if (error.config.url === "login" || error.config.url === "sign-in") {
          return Promise.reject(error.response);
        }

        // Auth error on request - Hijack promise response
        if (error.response && [401].indexOf(error.response.status) !== -1) {
          console.warn("AUTH ERROR (interceptor)");

          this.$toasted.clear();
          this.$toasted.global.appError({
            message: this.$t("Votre session a expirée, veuillez vous reconnecter."),
          });

          this.$store.dispatch("AUTH_LOGOUT").then(() => this.$router.push("/login"));

          return;
        }

        // TODO: network error
        // TODO: 404

        // return Promise.reject(error); // error.response?
        return Promise.reject(error.response);

        // Cancelling a promise?
        // https://github.com/axios/axios/issues/583
        // return new Promise(() => {});
      }
    );

    // Authentification : si un JWT est enregistré en localStorage, on le passe dans les headers de chaque requêtes.
    if (this.$store.state.userToken) HTTP.defaults.headers.common.Authorization = `Bearer ${this.$store.state.userToken}`;

    // Synchronisation du localStorage
    // L'application root surveille les mutations du Store (`subscribe`) et execute l'action STORAGE_SYNC pour mettre à jour le localStorage.
    this.$store.subscribe((mutation, state) => {
      this.$store
        .dispatch("STORAGE_SYNC", mutation)
        // .then((response) => console.info("STORAGE_SYNC done"))
        .catch((error) => console.warn("STORAGE_SYNC error"));
    });

    // Auto login?
    if (this.$router.currentRoute.name !== "login") this.autoLoginUser();

    // dayjs Plugins
    require("dayjs/locale/fr");
    const isoWeeksInYear = require("dayjs/plugin/isoWeeksInYear");
    const isLeapYear = require("dayjs/plugin/isLeapYear");
    const weekday = require("dayjs/plugin/weekday");
    const isoWeek = require("dayjs/plugin/isoWeek");
    const advancedFormat = require("dayjs/plugin/advancedFormat");
    this.$dayjs.extend(isoWeek);
    this.$dayjs.extend(isoWeeksInYear);
    this.$dayjs.extend(isLeapYear);
    this.$dayjs.extend(weekday);
    this.$dayjs.extend(advancedFormat);
    this.$dayjs.locale("fr"); //.format()
  },

  async mounted() {
    // Load options
    this.$store.commit("GET_OPTIONS");
    const options = this.$store.state.options; // from localstorage

    // Configure NProgress when the DOM is ready…
    NProgress.configure({ parent: ".app-header" });

    // Set language from localstorage (user preference) or user native language (by default)

    // localstorage language
    const _ll = this.$store.state.lang;

    // NOTE: could be "fr" or "fr-FR"
    const _ul = this?.user?.lang;

    // get lang code from supported languages list
    // const languages = this.$listLanguages();

    // get browser language
    // let _nl = "";
    // if (navigator.language) {
    // 	_nl = languages.filter((l) => l.includes(_nl));
    // 	if (_nl) _nl = _nl[0];
    // }

    // Choose in order from localStorage, user language or default language
    const _l = _ll || _ul || "fr-FR";

    // load language file (should be like "fr-FR")
    // `loadedLang` could be different from `_l` if the language is not supported.
    // In this case the default language will be loaded (fr-FR)
    const loadedLang = await this.$loadLanguageAsync(_l);
    console.log("loadedLang", loadedLang);

    // save current lang to store
    this.$store.dispatch("LANG", loadedLang);

    // GA track events
    // EventBus.$on("trackEvent", (action, label, value = 1) => {
    //   // console.log("EventBus", action, label);
    //   if (!appConfig.eventTracking) return false;
    //   this.$gtag.event(action, {
    //     event_category: "Application Infovac",
    //     event_label: label,
    //     value: value,
    //   });
    // });
  },

  computed: {
    user: function() {
      return this.$store.state.userData;
    },

    // Utilisateur connecté ?
    isAuthenticated: function() {
      return !!this.$store.state.userToken && !!this.$store.state.userData && this.$store.state.userData.registration_complete;
    },
  },

  methods: {
    // Connexion automatique si un token est présent mais sans données utilisateur
    autoLoginUser: function() {
      if (!this.$store.state.userToken || !!this.$store.state.userData) return;
      console.log("autoLoginUser");

      this.$store
        .dispatch("USER_LOGIN")
        .then(() => this.$router.push("dashboard"))
        .catch((error) => {
          this.$router.push("login");
          return this.$toasted.global.appError({
            message: `${this.$t("Impossible de vous connecter, <br>veuillez vous reconnecter à votre compte")} <a href="https://infovac.ch">infovac.ch</a>`,
          });

          // Session expirée
          // this.$toasted.global.appError({message: this.$t("Votre session a expirée, veuillez vous reconnecter.")});
        });
    },
  },
}).$mount("#app");
