// Config
import { appConfig } from "./config.js";

// https://alligator.io/vuejs/rest-api-axios/
import axios from "axios";

// NProgress
import { NProgress } from "./nprogress.js";

const HTTP = axios.create({
  baseURL: appConfig.API_BASE_URL,
});

// Indicateur de chargement à chaque requête AJAX
// https://www.digitalocean.com/community/tutorials/add-loading-indicators-to-your-vuejs-application

// start before a request is made
HTTP.interceptors.request.use((config) => {
  NProgress.start();
  return config;
});

// stop when a response is returned
HTTP.interceptors.response.use((response) => {
  NProgress.done();
  return response;
});

export { axios, HTTP };
