<template>
	<div class="language-selector">
		<div class="input-group">
			<select name="language"
							id="LangSelectSimple"
							:title="$t('Langue')"
							@input="this.setLanguage">
				<option :value="l"
								:selected="l === lang ? 'selected' : null"
								v-for="l in languages">{{ shortLangText(l) }}</option>
			</select>
		</div>
	</div>
</template>


<script>
export default {
	name: "LangSelectSimple",

	data: function () {
		return {
			languages: this.$listLanguages(),
		};
	},

	computed: {

		currentLang() {
			return this.$store.state.lang;
		},

		// Language selector value
		lang() {
			// Get lang from store
			if (this.currentLang && !this.languages.includes(this.currentLang)) return 'fr-FR'
			return this.currentLang;
		},

		// Current user
		user: function () {
			return this.$store.state.userData;
		},

	},

	methods: {

		shortLangText(value) {
			return value.split('-')[1];
		},

		// Set language
		setLanguage(e) {
			const l = e.target.value;

			// Load language
			this.$loadLanguageAsync(l).then(() => {
				// Save lang to store
				this.$store.dispatch("LANG", l);
				// Dispatch event to parents
				this.$emit("input", l);
			});
		},
	},


};
</script>

<style lang="scss" scoped>
.language-selector {

	select {
		cursor: pointer;
		padding: 0.5em 0.5em 0.35em 0.5em;
		margin-top: .2em;
	}


	.option__image {
		width: 1.5rem;
		aspect-ratio: 1/1;
		height: auto;
	}
}

::v-deep .multiselect {
	.multiselect__select {
		display: none;
	}

	.multiselect__tags {
		padding: 0;
	}

	.multiselect__option,
	.multiselect__single {
		display: flex;
		flex-flow: row nowrap;
		gap: 0.5rem;
		align-items: center;
		padding: 0.5em;
		margin: 0;

		.option__image,
		.option__desc {
			flex-shrink: 0;
		}

		.option__desc {
			flex-grow: 1;
		}
	}
}
</style>