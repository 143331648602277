import Vue from "vue";

// Store
import Vuex from "vuex";
Vue.use(Vuex);

// Axios
import { HTTP } from "./http-common.js";

// LocalStorage
// This will register the instance `Vue.$localStorage`
import Storage from "vue-web-storage";
Vue.use(Storage);

// Helpers
import { get } from "./libs/helpers.js";

// Store
export default new Vuex.Store({
  state: {
    options: Vue.$localStorage.get("options", {}),

    // Authentication
    userToken: Vue.$localStorage.get("userToken", null),
    userData: Vue.$localStorage.get("userData", null),

    // Dashboard
    dashboardFilters: Vue.$localStorage.get("dashboardFilters", null),

    // Local question draft
    questionDraft: Vue.$localStorage.get("questionDraft", null),

    // Dashboard Help displayed by defautl
    helpDisplayStatus: true,

    // Dashboard pagination
    currentPaginationOptions: {},

    // Week Questions pagination
    currentWeekQuestionsPaginationOptions: {},

    // current display language
    lang: Vue.$localStorage.get("lang"),

    // redirection after login
    redirect_uri: Vue.$localStorage.get("redirect_uri", ""),
  },

  /**
   *
   * GETTERS
   */
  getters: {
    currentLang: (state) => state.lang,
  },

  /**
   *
   *
   * MUTATIONS
   * L'App root surveille les mutation avec subscribe.
   */
  mutations: {
    SET_REDIRECT_URI: (state, uri) => {
      state.redirect_uri = uri;
      Vue.$localStorage.set("redirect_uri", uri);
    },

    /**
     * Fetch options from API
     */
    GET_OPTIONS: (state) => {
      return new Promise((resolve, reject) => {
        HTTP.get("/options")
          .then(function(response) {
            const payload = response.data.payload || null;
            state.options = { ...payload };
            Vue.$localStorage.set("options", state.options);
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    LANG: (state, lang) => {
      state.lang = lang;
      Vue.$localStorage.set("lang", lang);
    },

    HELP_DISPLAY_STATUS: (state, helpDisplayStatus) => {
      state.helpDisplayStatus = helpDisplayStatus;
    },

    STORE_DASHBOARD_FILTERS: (state, payload) => {
      state.dashboardFilters = payload;
    },

    RESET_QUESTION_DRAFT: (state) => {
      state.questionDraft = null;
    },

    // Update the local question data
    // dataFragment can be questionDraft.themes, questionDraft.details, etc.
    SAVE_QUESTION_DRAFT: (state, payload) => {
      const questionID = parseInt(payload.questionID) || 0;
      state.questionDraft = {
        ...state.questionDraft,
        ...payload.dataFragment,
        questionID: questionID,
      };
    },

    // Update state with new user data
    UPDATE_USER_DATA: (state, userData) => {
      state.userData = userData;
    },

    // Retrieve authentified user data
    USER_LOGIN: (state, userData) => {
      state.userData = userData;
    },
    USER_LOGOUT: (state, userData) => {
      state.userToken = null;
      state.userData = null;
      state.questionDraft = null;
    },

    // Update user token (Authorization Header)
    AUTH_SUCCESS: (state, userToken) => {
      state.userToken = userToken;
    },
    AUTH_LOGOUT: (state) => {
      state.userToken = "";
      state.userData = "";
    },

    // Update state with new current page for Week Questions Page
    SAVE_WEEK_QUESTIONS_CURRENT_PAGE: (state, weekQuestionsCurrentPage) => {
      state.currentWeekQuestionsPaginationOptions.savedCurrentPage = weekQuestionsCurrentPage;
    },
    // Update state with new current per page for Week Questions Page
    SAVE_WEEK_QUESTIONS_CURRENT_PER_PAGE: (state, weekQuestionsCurrentPerPage) => {
      state.currentWeekQuestionsPaginationOptions.perPage = weekQuestionsCurrentPerPage;
    },
  },

  /**
   *
   *
   * ACTIONS
   */
  actions: {
    // Store redirect uri (redirected after remote login)
    SET_REDIRECT_URI: ({ commit }, uri) => {
      commit("SET_REDIRECT_URI", uri);
    },

    // Set display language
    LANG: ({ commit, dispatch }, lang) => {
      commit("LANG", lang);
    },

    // Fetch specilaties
    GET_SPECIALTIES: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("/users/specialties")
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Update dashboard help display status
    HELP_DISPLAY_STATUS: ({ commit, dispatch }, status) => {
      commit("HELP_DISPLAY_STATUS", status);
    },

    // Update assignments
    PUT_ASSIGNMENT: ({ commit, dispatch }, assignment) => {
      return new Promise((resolve, reject) => {
        HTTP.put("/assignments", assignment)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Fetch assignments
    GET_ASSIGNMENTS: ({ commit, dispatch }, { periodStart, periodEnd }) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`/assignments/${periodStart}/${periodEnd}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Assign expert (userID) to question (questionID)
    POST_ASSIGN_EXPERT: ({ commit, dispatch }, { questionID, userID }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`questions/${questionID}/expert`, { expert_id: userID })
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Get all expert users
    GET_EXPERT_USERS: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("users/experts")
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Post answer to question
    POST_ANSWER: ({ commit, dispatch }, { questionID, payload }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionID}/answers`, payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Fetch question feedback
    GET_FEEDBACK: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`questions/${questionID}/feedback`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // User Feedback
    SEND_FEEDBACK: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("feedback", payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Remember dashboard filters
    STORE_DASHBOARD_FILTERS: ({ commit, dispatch }, payload) => {
      commit("STORE_DASHBOARD_FILTERS", payload);
    },

    // Update question read status
    PUT_QUESTION_READ_STATUS: ({ commit, dispatch }, { questionID }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`questions/${questionID}/read`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            console.error("PUT_QUESTION_READ_STATUS", error);
            reject(error);
          });
      });
    },

    // Update question starred status
    PUT_QUESTION_STARRED_STATUS: ({ commit, dispatch }, { questionID, starredStatus }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`questions/${questionID}/starred/${starredStatus}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Delete question ("draft" status only)
    DELETE_QUESTION: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.delete(`questions/${questionID}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // New question: save local question draft
    RESET_QUESTION_DRAFT: ({ commit, dispatch }) => {
      commit("RESET_QUESTION_DRAFT");
    },

    // Edit question: save local question draft
    SAVE_QUESTION_DRAFT: ({ commit, dispatch }, payload) => {
      commit("SAVE_QUESTION_DRAFT", payload);
    },

    // Set mulitple questions status to "publish"
    COMPLETE_QUESTIONS: ({ commit, dispatch }, questionIDs) => {
      return new Promise((resolve, reject) => {
        HTTP.post("questions/complete", { qids: questionIDs })
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Set question status to "publish"
    COMPLETE_QUESTION: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionID}/complete`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Set question status to "publish"
    PUBLISH_QUESTION: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionID}/publish`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Insert/Update a question (from a question draft)
    // TODO: drafts dataFragment?
    SAVE_QUESTION: ({ state, commit, dispatch }) => {
      const data = state.questionDraft;
      const questionID = parseInt(data.questionID);
      let req = null;

      // Insert/Update question
      req = new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionID}`, data)
          .then(function(response) {
            const payload = response.data.payload || null;
            const questionID = payload?.questionID || null;
            // Update local draft
            commit("SAVE_QUESTION_DRAFT", {
              dataFragment: {},
              questionID: questionID,
            });
            resolve(questionID);
          })
          .catch(function(error) {
            reject(error);
          });
      });
      return req;
    },

    // Récupération des thématiques
    GET_THEMES: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("themes")
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Delete question reference
    DELETE_DOCUMENT: ({ commit, dispatch }, hash) => {
      return new Promise((resolve, reject) => {
        HTTP.delete("files", { data: hash })
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Récupération d'une question
    GET_QUESTION_DATA: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`questions/${questionID}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Récupération des réponses
    GET_ANSWERS: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`questions/${questionID}/answers`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Récupération des questions
    // NOTE: Requête POST avec paramètres de pagination, tri, filtres, …
    // NOTE: les infos d'identification de l'utilisateur se trouvent dans le JWT envoyé avec chaque requête.
    // On récupère donc uniquement les questions qui concernent l'utilisateur connecté, en fonction des ses permissions.
    FETCH_QUESTIONS: ({ commit, dispatch }, { search, params }) => {
      const data = {
        search: search,
        params: params,
      };

      // console.log("FETCH_QUESTIONS", data);

      return new Promise((resolve, reject) => {
        HTTP.post("questions/search", data)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Récupération des questions
    // NOTE: les infos d'identification de l'utilisateur se trouvent dans le JWT envoyé avec chaque requête.
    // On récupère donc uniquement les questions qui concernent l'utilisateur connecté, en fonction des ses permissions.
    GET_QUESTIONS: ({ commit, dispatch }, { search, params }) => {
      // Search?
      const searchQuery = search ? `?s=${search}` : "";

      return new Promise((resolve, reject) => {
        HTTP.get(`questions${searchQuery}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    GET_WEEK_QUESTIONS: ({ commit, dispatch }, { periodStart, periodEnd, page, perPage }) => {
      const searchQuery = `week-questions/${periodStart}/${periodEnd}?page=${page}&perPage=${perPage}`;

      return new Promise((resolve, reject) => {
        HTTP.get(searchQuery)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    SAVE_WEEK_QUESTIONS_CURRENT_PAGE: ({ commit }, weekQuestionsCurrentPage) => {
      commit("SAVE_WEEK_QUESTIONS_CURRENT_PAGE", weekQuestionsCurrentPage);
    },

    SAVE_WEEK_QUESTIONS_CURRENT_PER_PAGE: ({ commit }, weekQuestionsCurrentPerPage) => {
      commit("SAVE_WEEK_QUESTIONS_CURRENT_PER_PAGE", weekQuestionsCurrentPerPage);
    },

    GET_DEFAULT_LINKS: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("links/default")
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    DELETE_DEFAULT_LINKS: ({ commit, dispatch }, linkId) => {
      return new Promise((resolve, reject) => {
        HTTP.delete(`links/default/${linkId}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    SAVE_DEFAULT_LINK: ({ commit, dispatch }, newLink) => {
      return new Promise((resolve, reject) => {
        HTTP.put("links/default", newLink)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    GET_QUESTION_LINKS: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`questions/${questionID}/links`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    SAVE_QUESTION_LINK: ({ commit, dispatch }, { questionId, links }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionId}/links`, links)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Authentification
    AUTH_REQUEST({ commit, dispatch }, authToken) {
      return new Promise((resolve, reject) => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        };

        // API call to authenticate user
        HTTP.post("sign-in", null, { headers: headers })
          .then(function(response) {
            const payload = response.data ? response.data.payload || null : null;
            const userToken = payload?.userToken || null;
            if (userToken === null) {
              dispatch("AUTH_LOGOUT");
              reject("userToken is null");
            } else {
              commit("AUTH_SUCCESS", userToken); // update status (mutation)
              resolve(response);
            }
          })
          .catch(function(error) {
            dispatch("AUTH_LOGOUT");
            console.info("Authentification error", error);
            reject("Authentification error", error);
          });
      });
    },

    // Dés-authentification
    AUTH_LOGOUT: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOGOUT");
        resolve();
      });
    },

    // Connexion de l'utilisateur authentifié (le JWT est passé automatiquement dans les en-têtes de chaque requête)
    USER_LOGIN: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        // API call to login user
        HTTP.post("login")
          .then(function(response) {
            const userData = response?.data.payload || null;

            if (userData === null) {
              console.error("Unexpected error", "userData is null");
              dispatch("AUTH_LOGOUT");
              reject("userData is null");
            } else {
              // update status (mutation)
              commit("USER_LOGIN", userData);
              resolve(response);
            }
          })
          .catch(function(err) {
            // login error
            dispatch("AUTH_LOGOUT");
            reject(err);
          });
      });
    },

    // Déconnexion et désauthentification de l'utilisateur
    USER_LOGOUT: ({ commit, dispatch, state }) => {
      return new Promise((resolve, reject) => {
        // Unvalidate tokens
        HTTP.post("logout")
          .then(function(response) {
            const payload = response.data.payload || null;
          })
          .catch(function(error) {
            reject(error);
          });

        // Update State and Storage
        commit("USER_LOGOUT");
        resolve();
      });
    },

    // Requestion new validation email
    NEW_VALIDATION_EMAIL: ({ commit, dispatch, state }, email) => {
      return new Promise((resolve, reject) => {
        HTTP.post("users/validate", { email: email })
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Check email validation token
    USER_VALIDATE: ({ commit, dispatch, state }, { userID, validationToken }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}/validate/${validationToken}`)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    UPDATE_EXPERT_ONCALL: ({ commit, dispatch, state }, { userID, status }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`users/experts/${userID}/${status}`)
          .then(function(response) {
            const updated = response?.data?.payload ? true : false;
            if (updated) {
              // commit("UPDATE_EXPERT_ONCALL", updated);
              resolve(updated);
            }
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Profile: update user
    UPDATE_USER_DATA: ({ commit, dispatch, state }, { userID, formData }) => {
      return new Promise((resolve, reject) => {
        // NOTE: The server (o2switch) does not support send multipart/form-data with PUT method (payload is empty)
        // Spoof "PUT" method to send multipart/form-data
        // https://stackoverflow.com/questions/54686218/laravel-vuejs-axios-put-request-formdata-is-empty
        // formData.append("_method", "put");

        HTTP.post(`users/${userID}/update-profile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(function(response) {
            const userData = response.data.payload || null;
            if (userData) {
              commit("UPDATE_USER_DATA", userData);
              resolve(userData);
            }
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Refresh Current User Data
     *
     * @param Int userID
     */
    REFRESH_USER: ({ commit, dispatch }, userID) => {
      return new Promise((resolve, reject) => {
        // load data
        dispatch("GET_USER_DATA", userID).then((response) => {
          // then commit UPDATE_USER_DATA to refresh state and localStorage
          commit("UPDATE_USER_DATA", response);
          // And return fresh user data
          resolve(response);
        });
      });
    },

    // Profile: get user data
    GET_USER_DATA: ({ commit, dispatch }, userID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`users/${userID}/profile`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // localStorage Sync
    // L'App root subscribe aux mutations du Store pour mettre en cache les données de certaines mutations.
    STORAGE_SYNC: ({ commit, state }, mutation) => {
      return new Promise((resolve, reject) => {
        // console.log("STORAGE_SYNC", mutation, state);

        if (mutation.type === "STORE_DASHBOARD_FILTERS") {
          Vue.$localStorage.set("dashboardFilters", state.dashboardFilters);
          resolve(mutation);
        }

        if (mutation.type === "RESET_QUESTION_DRAFT") {
          Vue.$localStorage.remove("questionDraft");
          resolve(mutation);
        }

        if (mutation.type === "SAVE_QUESTION_DRAFT") {
          Vue.$localStorage.set("questionDraft", state.questionDraft);
          resolve(mutation);
        }

        // Authentification réussie, on enregistre le userToken
        if (mutation.type === "AUTH_SUCCESS") {
          Vue.$localStorage.set("userToken", state.userToken);
          resolve(mutation);
        }

        // Erreur d'authentification ou Logout on supprime le userToken
        if (mutation.type === "AUTH_LOGOUT") {
          Vue.$localStorage.remove("userToken");
          Vue.$localStorage.remove("userData");
          Vue.$localStorage.remove("dashboardFilters");
          resolve(mutation);
        }

        // Connexion de l'utilisateur : on enregistre ses données
        if (mutation.type === "USER_LOGIN" || mutation.type === "UPDATE_USER_DATA") {
          Vue.$localStorage.set("userData", state.userData);
          resolve(mutation);
        }

        // Erreur de login ou Logout : on supprime les données de l'utilisateur
        if (mutation.type === "USER_LOGOUT") {
          console.log("USER_LOGOUT mutation");

          // Vue.$localStorage.clear(true);
          Vue.$localStorage.remove("userToken");
          Vue.$localStorage.remove("userData");
          Vue.$localStorage.remove("dashboardFilters");
          resolve(mutation);
        }

        // Si erreur : reject (mutation);
      });
    },
  },
  modules: {},
});
