import Vue from "vue";
import VueRouter from "vue-router";
import { NProgress } from "./nprogress.js";

Vue.use(VueRouter);

// Views components
const Login = () => import(/* webpackChunkName: "Login" */ "./views/Login.vue");
// const NewsletterConfirm = () => import(/* webpackChunkName: "NewsletterConfirm" */ "./views/NewsletterConfirm.vue");

// NOTE: Route level code-splitting with "webpackChunkName"
// This generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.

const routes = [
  // Default links
  // {
  //   path: "/default-links",
  //   name: "defaultLinks",
  //   component: () => import(/* webpackChunkName: "Assignments" */ "./views/DefaultLinks.vue"),
  //   // props: true,
  //   meta: {
  //     authenticated: true,
  //   },
  // },

  // Assignments
  {
    path: "/assignments*",
    name: "Assignments",
    component: () => import(/* webpackChunkName: "Assignments" */ "./views/Assignments.vue"),
    props: true,
    meta: {
      authenticated: true,
    },
  },

  // Home – Login
  {
    path: "/",
    name: "root",
    component: Login,
    meta: {
      title: "Connexion",
    },
  },

  // Login page
  {
    path: "/login",
    name: "login",
    component: Login,
  },

  // Registration - complete user profile
  {
    path: "/register",
    name: "register",
    // component: Register,
    component: () => import(/* webpackChunkName: "Register" */ "./views/Register.vue"),
  },

  // User Dashboard – questions lists
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "Dashboard" */ "./views/Dashboard.vue"),
    meta: {
      authenticated: true,
    },
  },

  // User Profile (optional email update confirmation)
  {
    path: "/user/:userProfileID/profile*",
    name: "userProfile",
    component: () => import(/* webpackChunkName: "UserProfile" */ "./views/UserProfile.vue"),
    props: true,
    meta: {
      authenticated: true,
    },
  },

  /*
	Questions
	*/

  // display question
  {
    path: "/question/:questionID",
    name: "question",
    // component: Question,
    component: () => import(/* webpackChunkName: "Question" */ "./views/Question.vue"),
    props: true,
    meta: {
      authenticated: true,
    },
  },

  // edit/new question : themes
  {
    path: "/edit-question-themes/:questionID",
    name: "questionEditThemes",
    // component: QuestionEditThemes,
    component: () => import(/* webpackChunkName: "QuestionEditThemes" */ "./views/QuestionEditThemes.vue"),
    props: true,
    meta: {
      authenticated: true,
    },
  },

  // edit/new question : details
  {
    path: "/edit-question-details/:questionID",
    name: "questionEditDetails",
    // component: QuestionEditDetails,
    component: () => import(/* webpackChunkName: "QuestionEditDetails" */ "./views/QuestionEditDetails.vue"),
    props: true,
    meta: {
      authenticated: true,
    },
  },

  // edit/new question : documents
  {
    path: "/edit-question-documents/:questionID",
    name: "questionEditDocuments",
    // component: QuestionEditDocuments,
    component: () => import(/* webpackChunkName: "QuestionEditDocuments" */ "./views/QuestionEditDocuments.vue"),
    props: true,
    meta: {
      authenticated: true,
    },
  },

  // display week questions
  {
    path: "/week-questions/:periodStart?/:periodEnd?",
    name: "weekQuestions",
    component: () => import(/* webpackChunkName: "WeekQuestion" */ "./views/WeekQuestions.vue"),
    props: true,
    meta: {
      authenticated: true,
    },
  },
];

// Router config
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Route gards: loader
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
