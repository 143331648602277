<template>
	<main id="app"
				v-cloak>
		<div class="app-wrapper">
			<app-header />
			<router-view />
			<outdated-browser-info />
		</div>
		<app-footer />
	</main>
</template>

<style lang="scss">
@import "src/scss/app.scss";
</style>

<script>
// @ is an alias to /src
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import outdatedBrowserInfo from "@/components/outdatedBrowser.vue";

export default {
	components: {
		AppHeader,
		AppFooter,
		outdatedBrowserInfo,
	},
};
</script>
