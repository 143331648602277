// https://kazupon.github.io/vue-i18n/guide/lazy-loading.html

import Vue from "vue";
import VueI18n from "vue-i18n";
import { axios } from "@/http-common.js";

Vue.use(VueI18n);

// default language (preloaded, hardcoded strings if empty)
import lang_fr from "@/lang/fr-FR.json";
const messages = {
  fr: lang_fr.fr_FR, // NOTE the underscore! (cf. loadLanguageAsync)
};

export const i18n = new VueI18n({
  fallbackLocale: "fr-FR", // hardcoded
  locale: "fr-FR",
  messages: messages,
});

// languages "cache"
const loadedLanguages = []; // default language (preloaded or hardcoded)

const setI18nLanguage = (lang) => {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  // lang is in format "fr-FR", keep the part before the "-"
  const html_lang = lang.split("-")[0];
  document.querySelector("html").setAttribute("lang", html_lang);
  return lang;
};

/**
 * Lang plugin
 */
export default {
  install(Vue, options) {
    // // create a mixin
    // Vue.mixin({
    //   created() {
    //     console.log("created?", Vue);
    //   },
    // });

    Vue.prototype.$listLanguages = () => {
      return ["de-DE", "fr-FR"];
    };

    /*
     * Async load language JSON file
			ex. this.$loadLanguageAsync(lang).then(console.log(`${lang} loaded`));
     */
    Vue.prototype.$loadLanguageAsync = async function(lang) {
      // If the same language
      if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
      }

      // If the language was already loaded
      if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
      }

      // Ensure the language is supported
      // (defaults to fr-FR)
      if (!["fr-FR", "de-DE"].includes(lang)) lang = "fr-FR";

      // If the language hasn't been loaded yet
      return await import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.json`).then((messages) => {
        const lang_key = lang.replace("-", "_");
        // console.info("import lang", `${lang} -> ${lang_key}`);

        i18n.setLocaleMessage(lang, messages[lang_key]);
        loadedLanguages.push(lang);
        // return setI18nLanguage(lang);
        return Promise.resolve(setI18nLanguage(lang));
      });
    };
  },
};
